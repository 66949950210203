<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-dropdown class="p-2 dropdown-child"
                                right
                                variant="primary"
                                :text="$t('common.label.actions')"
                    >
                        <b-dropdown-item @click="$root.$children[0].openModal('new-workflow-modal', {id: 0},shown)"
                        >
                            <font-awesome-icon class="mr-2" icon="plus"/>
                            {{ $t('common.button.create') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-for="time in [
                            'daily',
                            'hourly',
                            'every_ten_minutes',
                            'every_six_hours',
                            'every_four_hours',
                            'every_month',
                            'every_six_months',
                            'every_year',
                            ]"
                                         @click="execute(time)"
                                         :key="time"
                        >
                            <font-awesome-icon class="mr-2" icon="play"/>
                            Execute "{{ time }}" workflows
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.workflow.label.id') }}</b-th>
                            <b-th>{{ $t('settings.workflow.label.title') }}</b-th>
                            <b-th>{{ $t('settings.workflow.label.trigger') }}</b-th>
                            <b-th>{{ $t('settings.workflow.label.status') }}</b-th>
                            <b-th>{{ $t('settings.workflow.label.error_status') }}</b-th>
                            <b-th>{{ $t('settings.workflow.label.last_executed') }}</b-th>
                            <b-th>{{ $t('settings.workflow.label.active') }}</b-th>
                            <b-th>{{ $t('settings.workflow.label.actions') }}</b-th>
                        </b-thead>
                        <tbody>
                        <b-tr v-for="workflow in workflows" :key="workflow.id">
                            <b-td>{{ workflow.id }}</b-td>
                            <b-td>{{ workflow.title }}</b-td>
                            <b-td>
                                {{ $t('settings.workflow.triggers.' + workflow.trigger) }}:
                                <template v-if="workflow.trigger=='trigger'">
                                    {{ getTriggerById(workflow.trigger_options.trigger_id).name }}
                                    {{ workflow.trigger_options.time }}
                                </template>
                            </b-td>
                            <b-td>{{ getStatuses()[workflow.status] || '' }}</b-td>
                            <b-td>{{ getStatuses()[workflow.error_status] || '' }}</b-td>
                            <b-td>{{ workflow.last_executed | formatDate('DD.MM.YYYY HH:mm:ss') }}</b-td>
                            <b-td>
                                <CBadge :color="workflow.active ? 'success' : 'danger' ">
                                    {{ workflow.active ? $t('common.button.yes') : $t('common.button.no') }}
                                </CBadge>
                            </b-td>
                            <b-td>
                                <b-button
                                    @click="$root.$children[0].openModal('new-workflow-modal', {id: workflow.id},shown)"
                                    size="sm"
                                    variant="primary"
                                    class="mr-2"
                                    :title="$t('common.title.edit')"
                                >
                                    <font-awesome-icon icon="edit"/>
                                </b-button>

                                <b-button
                                    @click="changeActive(workflow)"
                                    size="sm"
                                    class="mr-1"
                                    :variant="workflow.active == 1 ? 'success' : 'danger'"
                                >
                                    <b-icon :icon="workflow.active == 1 ? 'check-circle' : 'x-circle'"></b-icon>
                                </b-button>

                                <b-button
                                    @click="copyWorkflow(workflow)"
                                    size="sm"
                                    class="mr-1"
                                    :variant="'warning'"
                                >
                                    <font-awesome-icon icon="copy"></font-awesome-icon>
                                </b-button>

                                <b-button @click="deleteWorkflow(workflow.id)"
                                          size="sm"
                                          variant="danger"
                                          :title="$t('common.title.delete')"
                                >
                                    <font-awesome-icon icon="trash"/>
                                </b-button>
                            </b-td>
                        </b-tr>
                        </tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'Settings-new-workflow',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            workflows: [],
        }
    },
    methods: {
        ...mapGetters('Workflow', ['getWorkflows']),
        ...mapGetters('CommonData', ['getStatuses', 'getAllWorkflowTriggers']),
        shown() {
            const workflowsFetch = this.$store.dispatch('Workflow/fetchWorkflows')

            Promise.all([workflowsFetch])
                .then(() => {
                    this.workflows = this.getWorkflows()
                })
                .finally(() => {
                    this.loading = false
                })
            this.$root.afterSettingsUpdate()
        },
        deleteWorkflow(id) {
            this.$removeConfirm('Workflow/deleteWorkflow', id, this.shown)
        },
        changeActive(workflow) {
            this.loading = true
            this.$store.dispatch('Workflow/saveWorkflow', {id: workflow.id, active: !workflow.active})
                .then((response) => {
                    this.shown()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        copyWorkflow(workflow) {
            this.$store.dispatch('Workflow/copyWorkflow', workflow.id)
                .then((response) => {
                    this.shown()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        execute(time) {
            this.loading = true
            this.$store.dispatch('Workflow/executeTime', time)
                .then((response) => {
                    this.shown()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getTriggerById(id) {
            let found = null
            Object.keys(this.getAllWorkflowTriggers()).forEach(key => {
                this.getAllWorkflowTriggers()[key].forEach(trigger => {
                    if (id == trigger.id) {
                        found = trigger
                    }
                })
            })

            return found
        }
    },
    mounted() {
        this.shown()
    },
}
</script>